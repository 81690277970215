<template>
	<v-container
		fill-height
        fluid>
		<v-row
			align="center"
			justify="center">
			<v-col
				cols="11"
				sm="9"
				md="8">
				<v-card color="transparent"
					elevation="0">
					<h1 class="display-1 font-weight-black font-italic primary--text my-10">Реєстрація</h1>
					<validation-observer
						ref="observer"
						v-slot="{ invalid, validated, handleSubmit }">
						<v-form @submit.prevent="handleSubmit(submit)">
							<v-row>
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-text-field-validatable
										v-model="user.name"
										name="name"
										type="text"
										label="ім'я"
										rules="required"
										required>
									</v-text-field-validatable>
								</v-col>

								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-text-field-validatable
										v-model="user.surname"
										name="surname"
										type="text"
										label="прізвище"
										rules="required"
										required>
									</v-text-field-validatable>
								</v-col>
							</v-row>

							<v-row>
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-text-field-validatable
										v-model="user.email"
										name="email"
										type="email"
										label="емейл"
										rules="required|email"
										required>
									</v-text-field-validatable>
								</v-col>
								
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-text-field-validatable
										v-model="user.password"
										name="password"
										type="password"
										label="пароль"
										rules="required|min:8"
										required>
									</v-text-field-validatable>
								</v-col>
								
							</v-row>

							<v-row class="mb-10 align-center">
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-checkbox-validatable
										v-model="user.agree"
										type="checkbox"
										label="Я даю згоду на обробку моїх персональних даних"
										:value="true"
										:rules="{
											required: {
												allowFalse: false,
											},
										}"
										required>
										<template #label>
											<span>Я погоджуюсь із  <router-link :to="{ name: 'privacy-policy' }">ПОЛІТИКОЮ КОНФІДЕНЦІЙНОСТІ</router-link></span>
										</template>
									</v-checkbox-validatable>
								</v-col>

								<v-col
									cols="12"
									md="6">
									<v-alert
										:value="!invalid"
										type="success"
										transition="slide-x-transition"
										dense
										text>
										Ми наполегливо просимо тебе <strong>запам'ятати чи зберегти пароль</strong>, оскільки він буде необхідний для доступу на платформу
									</v-alert>
									<v-btn
										type="submit"
										color="btnBg"
										class="btnTxt--text"
										v-ripple="{ class: 'success--text' }"
										:loading="loading"
										:disabled="invalid">
										Зареєструватись
									</v-btn>
								</v-col>
							</v-row>
							
							<small class="grey--text mb-10">Вже маєш акаунт? Тоді тобі сюди: <router-link :to="{ name: 'login' }">ВХІД</router-link> </small>

						</v-form>
					</validation-observer>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	data: () => ({
		user: {
			name: '',
			surname: '',
			email: '',
			password: '',
			agree: null,
		},
		loading: false,
	}),
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		...mapActions('auth', [
			'register',
		]),
		async submit() {
			try {
				this.loading = true
				const success = await this.register(this.user)
				if (success) {
					this.$router.push({
						name: 'home',
					})
				}
			} catch (error) {
				this.$refs.observer.setErrors(error?.response?.data?.errors)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>