var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"11","sm":"9","md":"8"}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('h1',{staticClass:"display-1 font-weight-black font-italic primary--text my-10"},[_vm._v("Реєстрація")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field-validatable',{attrs:{"name":"name","type":"text","label":"ім'я","rules":"required","required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field-validatable',{attrs:{"name":"surname","type":"text","label":"прізвище","rules":"required","required":""},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field-validatable',{attrs:{"name":"email","type":"email","label":"емейл","rules":"required|email","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field-validatable',{attrs:{"name":"password","type":"password","label":"пароль","rules":"required|min:8","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1),_c('v-row',{staticClass:"mb-10 align-center"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-checkbox-validatable',{attrs:{"type":"checkbox","label":"Я даю згоду на обробку моїх персональних даних","value":true,"rules":{
											required: {
												allowFalse: false,
											},
										},"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Я погоджуюсь із "),_c('router-link',{attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v("ПОЛІТИКОЮ КОНФІДЕНЦІЙНОСТІ")])],1)]},proxy:true}],null,true),model:{value:(_vm.user.agree),callback:function ($$v) {_vm.$set(_vm.user, "agree", $$v)},expression:"user.agree"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-alert',{attrs:{"value":!invalid,"type":"success","transition":"slide-x-transition","dense":"","text":""}},[_vm._v(" Ми наполегливо просимо тебе "),_c('strong',[_vm._v("запам'ятати чи зберегти пароль")]),_vm._v(", оскільки він буде необхідний для доступу на платформу ")]),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'success--text' }),expression:"{ class: 'success--text' }"}],staticClass:"btnTxt--text",attrs:{"type":"submit","color":"btnBg","loading":_vm.loading,"disabled":invalid}},[_vm._v(" Зареєструватись ")])],1)],1),_c('small',{staticClass:"grey--text mb-10"},[_vm._v("Вже маєш акаунт? Тоді тобі сюди: "),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("ВХІД")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }